import { api } from '../helpers/api';
import { wellbeingConstants } from '../constants/wellbeing';

export const getSummary = (id, callback) => async dispatch => {

	try{
		//const request = await api().post(`/questionnaire/summary/${id}`, { groups: groups });
		const request = await api().get(`/wellbeing/questionnaire/summary/${id}`);
		
		dispatch({
			type: wellbeingConstants.GET_SUMMARY,
			payload: request,
			id: id
		});
		
		if(callback){
			callback();
		}
		
		return true;
	}
	catch(error){
		 console.log('error', error)
	}
}

export const getQuestions = () => async dispatch => {
	
	try{
		const request = await api().get('/wellbeing/questionnaire');
		
		dispatch({
			type: wellbeingConstants.GET_QUESTIONS,
			payload: request
		});
		
		return true;
	}
	catch(error){
		 console.log('error', error)
	}
}