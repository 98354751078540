import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom'; 
import { Link } from 'react-router-dom';
import Loading from '../../components/chrome/loading';
import Button from '../../components/chrome/button';
import FormField from '../../components/form/field';
import EmptySection from '../../components/chrome/empty';
import { Table, Thead, Th, Tr, Td } from "reactable";
import { getBrand } from '../../actions/brand';
import _ from 'lodash';
import moment from 'moment';
import InfoBox from '../../components/chrome/info_box';
import { getSummary } from '../../actions/wellbeing';
import { saveAs } from 'file-saver';

const BrandsView = props => {
	
	const { id } = useParams();
	
	if(!id){
		props.history.push('/brands');
	}
	
	const dispatch = useDispatch();
	const brand = useSelector(state => state.brand);
	const wellbeing = useSelector(state => state.wellbeing);
	
	useEffect(() => {
		console.log('useEffect', 'BrandsView')
		dispatch(getBrand(id));
	}, []);
	
	useEffect(() => {
		if(brand.brands && brand.brands[id] && brand.brands[id].wellbeing){
			dispatch(getSummary(id));
		}
	
	}, [brand]);
	
	if(!brand.brands || !brand.brands[id]){
		
		return (
			<Loading />
		);
	}
	
	const details = brand.brands[id];
		
	const onCodeClick = (id) => {
		props.history.push(`/brands/${details.id}/code/${details.id}/${id}`);
	}
	
	console.log('wellbeing', wellbeing)
	
	const Questionnaire = () => {
				
		if(!wellbeing.brands || !wellbeing.brands[id]){
		
			return (
				<Loading />
			);
		}
		
		const summary = wellbeing.brands[id];

		const exportToExcel = () => {
			
			const XLSX = require("xlsx");
			const workbook = XLSX.utils.book_new();
			let sheet = [];

			_.forEach(summary, (question, key) => {
				
				sheet = [];
		
				_.forEach(question.options, (option, data) => {
					
					let row = {
						'Answer': option.answer
					}
					
					_.forEach(question.answers, (answers, date) => {
						
						let month_total = 0;
						let month_percent = 0;
						let rolling_total = 0;
						let rolling_percent = 0;
						
						let tmp = 0;
						
						if(answers.answered.length > 0){
							
							if(tmp = _.find(answers.answered, { option_id: option.id })){
								month_total = tmp.total;
							}
						}
						
						if(answers.rolling.length > 0){
							
							if(tmp = _.find(answers.rolling, { option_id: option.id })){
								rolling_total = tmp.total;
							}
						}
						
						date = moment(date, 'YYYY-MM').format('MMM YYYY');
						row[`${date} Total`] = month_total;
						row[`${date} Rolling`] = rolling_total;
					})
					
					sheet.push(row);
				})
				
				
				let name = `${key+1} ${question.question}`;
				
				name = name.replaceAll('/', ' ')
				
								
				console.log(name, sheet)
				
				const worksheet = XLSX.utils.json_to_sheet(sheet);
				XLSX.utils.book_append_sheet(workbook, worksheet, name.substring(0,31));
			});
			
			
			

			const fileName = `Wellbeing-${brand.brands[id].name.replaceAll(' ', '_')}`;
			const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
			const blob = new Blob([excelBuffer], {type: 'application/octet-stream'});
			saveAs(blob, `${fileName}.xlsx`);
		};
	
		return (
			<div className="panel">
				<div className="header">
					<div>
						<div>
							<i className="fal fa-info-circle"></i>
							<h3>Hapori Wellbeing Questionnaire Overview</h3>
							<p>
								An overview to the questionnaire responses for the users of {details.name}.
							</p>
						</div>
						<button 
							onClick={exportToExcel}
							style={{
								position: 'absolute',
								top: 0,
								right: 0
							}}
						>
							Export to Excel
						</button>
					</div>
				</div>
				<div className="content">
				
					{_.map(summary, (question, key) => {
						
						let totals = {
							month: {},
							rolling: {}
						};
						 
						return (
							<React.Fragment key={key}>
							
								<strong>{question.question}</strong>
								
								<div className="table-responsive mt-20 mb-50">
									<table className="data-table">
										<thead>
											<tr>
												<th rowSpan={2}>Answer</th>
												{_.map(question.answers, (answers, date) => {
													
													let total_month = 0;
													let total_rolling = 0
																						
													if(answers.answered.length > 0){
														total_month = _.sumBy(answers.answered, (o) => { return parseFloat(o.total); });
													}
													
													if(answers.rolling.length > 0){
														total_rolling = _.sumBy(answers.rolling, (o) => { return parseFloat(o.total); });
													}									            
													
													totals.month[date] = total_month;
													totals.rolling[date] = total_rolling;
													
													return(
														<th colSpan={4} key={date} className="center">
															{moment(date, 'YYYY-MM').format('MMM YYYY')}
														</th>
													);
												})}
											</tr>
											<tr>											
												{_.map(question.answers, (answers, date) => {
													
													return(
														<React.Fragment key={`answer_${key}_${date}`}>
															<th width="100" className="center">Month Total</th>
															<th width="100" className="center">Month %</th>
															<th width="100" className="center">Rolling Total</th>
															<th width="100" className="center">Rolling %</th>
														</React.Fragment>
													);
												})}
											</tr>											
										</thead>
										<tbody>
											{_.map(question.options, (option, key2) => {
												
												return (
													<tr key={`answer_${key}_${key2}`}>
														<td>
															{option.answer}
														</td>
														
														{_.map(question.answers, (answers, date) => {
															
															let month_total = 0;
															let month_percent = 0;
															let rolling_total = 0;
															let rolling_percent = 0;
															
															let tmp = 0;
															
															if(answers.answered.length > 0){
																
																if(tmp = _.find(answers.answered, { option_id: option.id })){
																	month_total = tmp.total;
																}
															}
															
															if(answers.rolling.length > 0){
																
																if(tmp = _.find(answers.rolling, { option_id: option.id })){
																	rolling_total = tmp.total;
																}
															}
															
															if(month_total > 0){
																month_percent = (month_total/totals.month[date])*100;
															}
															
															if(rolling_total > 0){
																rolling_percent = (rolling_total/totals.rolling[date])*100;
															}
															
															return(
																<React.Fragment key={`answer_${key}_${key2}_${date}`}>
																	<td className="center">{month_total}</td>
																	<td className="center">{month_percent.toFixed()}%</td>
																	<td className="center">{rolling_total}</td>
																	<td className="center">{rolling_percent.toFixed()}%</td>
																</React.Fragment>
															);
														})}
													</tr>
												);
											})}
										</tbody>
									</table>
								</div>
							</React.Fragment>
						);
					})}
				</div>
			</div>
		);
	}
	
	return (
		<div>
		    
		    <div id="page-header">
				<h2>
					{details.name}
				</h2>
				
				<div id="actions">
					<Button
						label="New Hub Item"
						url={`/brands/${details.id}/item?brand=${details.id}`}
						split={[
							{
								label: 'Send Notification',
								url: `/brands/${details.id}/compose?brand=${details.id}`
							},
							{
								label: 'New Signup Code',
								url: `/brands/${details.id}/code/${details.id}`
							}	
						]}
					/>
				</div>
			</div>
			
			<div className="row">
				<div className="col-12 col-sm-6 col-md-4">
					<div className="panel">
			    		<div className="header">
			    			<i className="fal fa-building"></i>
			    			<h3>Profile</h3>
			    			<p>
			    				 Brand profile and details.
			    			</p>
			    		</div>
			    		<div className="content">
    		
							<div className="mb-15">
								<label className="label-main">Landing Page</label>
								<div>
									<a href={`https://${details.domain}`} target="_blank">{details.domain}</a>
								</div>
							</div>
							
							<div className="mb-15">
								<label className="label-main">Created</label>
								<div>
									{moment(details.created.date).format('DD/MM/YYYY')}
								</div>
							</div>
							
							<div className="mb-15">
								<label className="label-main">Hapori Wellbeing</label>
								<div>
									{details.wellbeing ? 'Enabled' : 'Disabled'}
								</div>
							</div>
																					
							<div className="mb-15">
								<label className="label-main">
									Signup Codes
									
									<Link to={`/brands/${details.id}/code/${details.id}`}>
										Add new
									</Link>
								</label>
								
								{details.codes.length > 0 && 
									<div className="table-responsive">
										<Table 
											className="data-table row-click" 
											id="table"
											sortable={[
												'code',
												'groups'
											]}
											defaultSort={{column: 'code', direction: 'asc'}}
											hideFilterInput
										>
											<Thead>
												<Th column="code" className="sorting">Code</Th>
												<Th column="groups" className="sorting">Groups</Th>										
											</Thead>
											{_.map(details.codes, (code, key) => {
												
												return (
													<Tr key={`code_${key}`}
														onClick={() => onCodeClick(code.id) } 
														className="clickabale"
													>
														<Td column="code">
															{code.code}
														</Td>
														<Td column="groups">
															{code.groups.length}
														</Td>
													</Tr>
												);
											})}
										</Table>
									</div>
								|| 
									<React.Fragment>
										There are currently no sign up codes!
									</React.Fragment>
								}
							</div>

							{/*<Link to={`/brands/${details.id}/brand/${details.id}`} className="btn full-width mb-10">
								Edit Profile
							</Link>*/}
						</div>
					</div>
				</div>
				<div className="col-12 col-sm-6 col-md-8">	
					<div className="panel">
			    		<div className="header">
			    			<i className="fal fa-info-circle"></i>
			    			<h3>Overview</h3>
			    			<p>
			    				Quickly see the state of play for {details.name}.
			    			</p>
			    		</div>
			    		<div className="content">
								
							<div className="row pt-3">								
								<div className="col-md-4 pb-md-0">
									<InfoBox 
										title="Hub Items" 
										title_sub={`Last item ${details.items.last ? moment(details.items.last).format('DD/MM/YYYY') : 'NA'}`}
										value={details.items.total}
										cta={{
											label: 'View Items',
											url: `/items/${details.id}`
										}}
									/>
								</div>
								
								<div className="col-md-4 pb-md-0">
									<InfoBox 
										title="Active Alerts" 
										title_sub={`Last alert ${details.alerts.last ? moment(details.alerts.last).format('DD/MM/YYYY') : 'NA'}`}
										value={details.alerts.total}
										cta={{
											label: 'View Alerts',
											url: `/alerts?brand=${details.id}`
										}}
									/>
								</div>
								<div className="col-md-4 pb-md-0">
									<InfoBox 
										title="Total Users" 
										title_sub={`Last signup ${details.users.last ? moment(details.users.last).format('DD/MM/YYYY') : 'NA'}`}
										value={details.users.total}
										cta={{
											label: 'View Users',
											url: `/users?brand=${details.id}`
										}}
									/>
								</div>
							</div>
						</div>
					</div>					
				</div>
			</div>
			{details.wellbeing && 
				<Questionnaire />		
			}
		</div>
	);
}

export default BrandsView;